import * as React from "react"
import { data } from "./data"
import { NewsMenu } from "./NewsMenu"
import { NewsList } from "./NewsList"
import { RelevantResources } from "./RelevantResources"
import { theme } from "../../theme"
import { Box, Flex } from "rebass/styled-components"

export const News = () => {
  return (
    <Box bg={theme.colors.bgLight}>
      <NewsMenu />

      <NewsList {...data.inTheNews} />

      <NewsList {...data.research} />

      <RelevantResources />
    </Box>
  )
}
