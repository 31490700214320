import * as React from "react"
import { ContentWrapper } from "../ContentWrapper"
import { Box, Flex } from "rebass/styled-components"
import { data } from "./data"
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export const NewsMenu = () => {
  return (
    <Flex style={{ borderBottom: "1px solid #222", minHeight: 74 }} pt={"28px"}>
      <ContentWrapper maxWidth="1290px">
        <Flex alignItems="flex-end">
          <Box
            width={[1, 1, "330px"]}
            fontFamily="Arnhem"
            fontStyle="normal"
            fontWeight={300}
            fontSize="32px"
            pb={"2px"}
          >
            {data.menu.title}
          </Box>
          <MenuItems>
            {data.menu.items.map(item => (
              <Box
                key={item.title}
                fontFamily="Gentona-Book"
                fontSize="18px"
                lineHeight="24px"
                alignItems="center"
                mr={[3, 3, 3, "52px"]}
              >
                <AnchorLink to={`${item.anchor}`} key={item.anchor}>
                  <Dot color={item.color} />
                  {item.title}
                </AnchorLink>
              </Box>
            ))}
          </MenuItems>
        </Flex>
      </ContentWrapper>
    </Flex>
  )
}

const Dot = styled.div<{ color?: string }>`
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 1px;
  ${({ color }) => `background: ${color};`}
  height: 10px;
  width: 10px;
  border-radius: 50%;
`

const MenuItems = styled.div`
  display: none;
  a {
    text-decoration: none;
    font-family: Gentona-Book;
    font-size: 18px;
    line-height: 24px;
    color: #35786e;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: flex;
  }
`
