import * as React from "react"
import Layout from "../components/common/Layout"
import { News } from "../components/news/News"
import SEO from "../components/seo"

const App = () => {
  return (
    <Layout>
      <SEO title="News & Resources" />
      <News />
    </Layout>
  )
}

export default App
