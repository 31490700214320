import React from "react"
// @ts-ignore
import newsShape from "../../images/shapes_news.svg"
// @ts-ignore
import resourcesShape from "../../images/shapes_relevant_resources.svg"
// @ts-ignore
import researchShape from "../../images/shapes_research.svg"

export const data = {
  menu: {
    title: "News and Resources",
    items: [
      {
        color: "#CF4520",
        title: "In the News",
        anchor: "/news/#in-the-news",
      },
      {
        color: "#DDCF54",

        title: "Research",
        anchor: "/news/#research",
      },
      {
        color: "#54A6DD",
        title: "Relevant Resources",
        anchor: "/news/#relevant-resources",
      },
    ],
  },
  inTheNews: {
    anchor: "in-the-news",
    title: "In the News",
    shape: newsShape,
    items: [
      {
        url:
          "https://www.scientificamerican.com/article/how-to-protect-yourself-from-ticks-and-the-dangerous-diseases-they-spread/",
        source: "Scientific American",
        heading:
          "How to Protect Yourself from Ticks and the Dangerous Diseases They Spread",
        abstract:
          "Experts explain why tick-borne illnesses are increasing in the U.S. and offer tips to prevent bites.",
      },
      {
        url:
          "https://www.washingtonpost.com/wellness/2023/powassan-ticks-symptoms-contagious-lyme/",
        source: "The Washington Post",
        heading:
          "What to know about the Powassan virus disease, a tick-borne illness",
        abstract:
          "The rare ailment, which tends to be much more serious than Lyme disease, is on the rise.",
      },
      {
        url:
          "https://www.army.mil/article/267142/miltick_study_finds_use_of_permethrin_treated_uniforms_can_reduce_tick_borne_illness_risk",
        source: "U.S. Army",
        heading:
          "MilTICK study finds use of permethrin-treated uniforms can reduce tick-borne illness risk",
        abstract:
          "A recent study conducted by biologists who manage the Department of Defense Military Tick Identification/Infection Confirmation Kit, or MilTICK, program, found that ticks submitted to the program by service members wearing permethrin-treated uniforms were significantly less likely to have become engorged.",
      },
    ],
  },
  research: {
    shape: researchShape,
    anchor: "research",
    title: "Research",
    items: [
      {
        url: "https://www.liebertpub.com/toc/vbz/23/4",
        source: "Vector-Borne and Zoonotic Diseases",
        heading: "Special Issue: Lyme Disease in Europe",
        abstract:
          "This special issue of Vector-Borne and Zoonotic Diseases presents 10 up-to-date reviews of the epidemiology of LB in Europe over the past 15 years, complemented by granular analyses of electronic medical record (EMR) databases and public health surveillance data (laboratory and clinical diagnoses) from multiple European countries. Collectively, this special issue aims to collect all the available epidemiological knowledge of LB in Europe, highlight the growing disease burden problem, and call attention to areas where further research and harmonization are needed to better define the epidemiology of the disease and support preventive interventions.",
      },
    ],
  },
  relevantResources: {
    shape: resourcesShape,
    anchor: "relevant-resources",
    title: "Relevant Resources",
    items: [
      {
        title: "Prevention and Symptoms",
        items: [
          {
            title: "Johns Hopkins Medicine Lyme Disease Research Center",
            items: [
              {
                title:
                  "Lyme Disease Prevention, Tick Removal, & Health and Wellness Tips",
                url:
                  "https://www.hopkinslyme.org/lyme-disease/lyme-disease-lifestyle-options-and-prevention-measures-to-enhance-health/",
              },
              {
                title: "Lyme Disease Signs & Symptoms",
                url:
                  "https://www.hopkinslyme.org/lyme-disease/lyme-disease-signs-symptoms/",
              },
              {
                title: "Lyme Disease Frequently Asked Questions (FAQs)",
                url:
                  "https://www.hopkinslyme.org/lyme-disease/lyme-disease-frequently-asked-questions/",
              },
            ],
          },
          {
            title: "CDC",
            items: [
              {
                title: "Preventing Tick Bites",
                url: "https://www.cdc.gov/ticks/avoid/on_people.html",
              },
              {
                title: "Tick Removal",
                url: "https://www.cdc.gov/ticks/removing_a_tick.html",
              },
              {
                title: "Symptoms of Tickborne Illness",
                url: "https://www.cdc.gov/ticks/symptoms.html",
              },
            ],
          },
        ],
      },
      {
        title: "US Resources",
        items: [
          {
            title: "CDC",
            items: [
              {
                title: "Tick Bite Data Tracker",
                url: "https://www.cdc.gov/ticks/tickedvisits/index.html",
              },
            ],
          },
          {
            title: "Companion Animal Parasite Council",
            items: [
              {
                title: "Parasite Prevalence Maps",
                url: "https://capcvet.org/maps/#/",
              },
            ],
          },
          {
            title: "Maine Tracking Network",
            items: [
              {
                title: "Near Real-Time Tickborne Disease Data",
                url:
                  "https://data.mainepublichealth.gov/tracking/nearrealtime_news",
              },
            ],
          },
          {
            title: "California Department of Public Health",
            items: [
              {
                title: "Lyme Disease in California ArcGIS StoryMap",
                url:
                  "https://storymaps.arcgis.com/stories/f64d0c19a3ab42cf90e8ce38397e96e0",
              },
            ],
          },
          {
            title: "Upstate Medical University",
            items: [
              {
                title: "New York Tick Surveillance Dashboard",
                url: "https://nyticks.org/tick-testing-results",
              },
            ],
          },
          {
            title: "University of Rhode Island",
            items: [
              {
                title: "TickEncounter",
                url: "https://tickencounter.org/",
              },
            ],
          },
          {
            title: "Cary Institute of Ecosystem Studies",
            items: [
              {
                title: "Lyme & Tick-borne Disease",
                url:
                  "https://www.caryinstitute.org/our-expertise/disease-ecology/lyme-tick-borne-disease",
              },
            ],
          },
          {
            title: "Columbia University",
            items: [
              {
                title: "New York City Ticks",
                url: "https://nycticks.e3b.columbia.edu/",
              },
            ],
          },
          {
            title: "Bay Area Lyme Foundation",
            items: [
              {
                title: "Citizen Science Tick Maps",
                url:
                  "https://www.bayarealyme.org/our-research/citizen-science-free-tick-testing-program/",
              },
            ],
          },
        ],
      },
      {
        title: "Canadian Resources",
        items: [
          {
            title: "Government of Canada",
            items: [
              {
                title: "Risk of Lyme Disease to Canadians",
                url:
                  "https://www.canada.ca/en/public-health/services/diseases/lyme-disease/risk-lyme-disease.html",
              },
            ],
          },
          {
            title: "Bishop’s University",
            items: [
              {
                title: "eTick Public Tick Map",
                url: "https://www.etick.ca/etickapp/en/ticks/public/map",
              },
            ],
          },
          {
            title: "Companion Animal Parasite Council",
            items: [
              {
                title: "Parasite Prevalence Maps",
                url: "https://capcvet.org/maps/#/",
              },
            ],
          },
          {
            title: "Canadian Lyme Disease Research Network",
            items: [
              {
                title: "Research Projects",
                url: "https://www.clydrn.ca/research-project/",
              },
              {
                title: "Resources",
                url: "https://www.clydrn.ca/resources/",
              },
            ],
          },
        ],
      },
      {
        title: "European Resources",
        items: [
          {
            title: "European Centre for Disease Prevention and Control",
            items: [
              {
                title: "Surveillance Atlas of Infectious Diseases",
                url:
                  "https://www.ecdc.europa.eu/en/surveillance-atlas-infectious-diseases",
              },
              {
                title: "Tick Maps",
                url:
                  "https://www.ecdc.europa.eu/en/disease-vectors/surveillance-and-disease-data/tick-maps",
              },
            ],
          },
        ],
      },
    ],
  },
}
