import * as React from "react"
import { ContentWrapper } from "../ContentWrapper"
import { Box, Flex, Heading, Link, Text } from "rebass/styled-components"
import { theme } from "../../theme"
import { data } from "./data"
import { LinkIcon } from "../icons/Link"
// @ts-ignore
import shape from "../../images/shapes_news.svg"

interface IRelevantResources {
  title: string
  items: {
    title: string
    items: {
      title: string
      items: {
        title: string
        url: string
      }[]
    }[]
  }[]
}
export const RelevantResources = () => {
  const { title, items, anchor, shape } = data.relevantResources

  return (
    <ContentWrapper maxWidth="1290px" py={["40px", "40px", "80px"]} id={anchor}>
      <Box
        width={[1, 1, "330px"]}
        fontFamily="Gentona-Book"
        fontSize="22px"
        mb={"64px"}
        mt={"-5px"}
      >
        {title}
        <Box width={[0, 0, "300px"]} style={{ position: "absolute" }}>
          <img src={shape} style={{ transform: "translate(-50%, 120px)" }} />
        </Box>
      </Box>
      <Flex alignItems="flex-start" flexWrap="wrap">
        {items.map(section => (
          <>
            <Box
              width={[1, 1, "330px"]}
              fontFamily="Gentona-Book"
              fontSize="18px"
              mb={"64px"}
            >
              <Text
                fontSize={["16px"]}
                color={theme.colors.dark}
                mb={["30px"]}
                lineHeight={"24px"}
                fontFamily="Gentona-Book"
              >
                {section.title}
              </Text>
            </Box>
            <Box width={[1, 1, "calc(100% - 330px)"]} mb={["64px"]}>
              <Box>
                <Box>
                  {section.items.map(entity => (
                    <Box
                      style={{ borderBottom: "1px solid #222222" }}
                      mb={[3]}
                      key={entity.title}
                    >
                      <Heading
                        fontSize={["20px", "20px", "24px"]}
                        fontFamily="Arnhem"
                        lineHeight={["36px", "36px", "130%"]}
                        color={theme.colors.dark}
                        mb={"20px"}
                      >
                        {entity.title}
                      </Heading>
                      {entity.items.map(link => (
                        <Link href={link.url} key={link.title}>
                          <Flex alignItems="center" mb={"13px"}>
                            <LinkIcon />
                            <Text
                              color={theme.colors.body}
                              width={"100%"}
                              ml={"10px"}
                            >
                              {link.title}
                            </Text>
                          </Flex>
                        </Link>
                      ))}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </>
        ))}
      </Flex>
    </ContentWrapper>
  )
}
