import { ContentWrapper } from "../ContentWrapper"
import { Box, Flex, Heading, Link, Text } from "rebass/styled-components"
import { theme } from "../../theme"
import * as React from "react"
import styled from "styled-components"

interface INewsList {
  title: string
  anchor?: string
  shape?: string
  items: {
    url: string
    source: string
    heading: string
    abstract: string | React.ReactNode
  }[]
}

export const NewsList = ({ title, items, anchor, shape }: INewsList) => (
  <ContentWrapper maxWidth="1290px" id={anchor}>
    <Flex
      alignItems="flex-start"
      flexWrap="wrap"
      py={["40px", "40px", "80px"]}
      style={{ borderBottom: "1px solid #222222" }}
    >
      <Box
        width={[1, 1, "330px"]}
        fontFamily="Gentona-Book"
        fontSize="22px"
        mb={"64px"}
        mt={"-5px"}
      >
        {title}
        <Box width={[0, 0, 1]}>
          <img src={shape} style={{ transform: "translate(-50%, 20px)" }} />
        </Box>
      </Box>
      <Box width={[1, 1, "calc(100% - 330px)"]}>
        {items.map(item => (
          <Box
            key={item.heading}
            fontFamily="Gentona-Book"
            fontSize="18px"
            lineHeight="24px"
            alignItems="center"
            mr={[3, 3, 3, "52px"]}
          >
            <Text
              fontSize={["17px"]}
              color={theme.colors.dark}
              mb={["30px"]}
              fontFamily="Gentona-Book"
            >
              {item.source}
            </Text>
            <Link target="_blank" href={item.url}>
              <Heading
                fontSize={["32px", "32px", "40px"]}
                fontFamily="Arnhem"
                lineHeight={["36px", "36px", "130%"]}
                color={theme.colors.dark}
                mb={"20px"}
              >
                {item.heading}
              </Heading>
            </Link>
            <Text
              color={theme.colors.dark}
              mb={["80px", "80px", "72px"]}
              fontSize={["17px"]}
              fontFamily="Gentona-Light"
              lineHeight="159%"
            >
              {item.abstract}
            </Text>
          </Box>
        ))}
      </Box>
    </Flex>
  </ContentWrapper>
)

const ShapeBox = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    top: 300px;
    position: absolute;
    display: block;
  }
`
